import Tracker from '@openreplay/tracker';

import { Account, User } from '@pushwoosh/session-store';
import { isUserActionsTrackingOff } from './app.helpers';

const isProductionMode = process.env['NODE_ENV'] === 'production';

const projectToken = 'En1WwFrGb3SI1CAuecqC';
const tracker = new Tracker({
  projectKey: projectToken,
  ingestPoint: 'https://open-replay.pushwoosh.com/ingest',
  disableStringDict: true,
});

export function initOpenReplaySession(): void {
  if (!isProductionMode || isUserActionsTrackingOff()) {
    return;
  }

  tracker.start();
}

export function identifyOpenReplaySession(user: User, account: Account): void {
  if (!isProductionMode || isUserActionsTrackingOff()) {
    return;
  }

  tracker.setUserID(user.email);
  tracker.setMetadata('AccountID', account.id.toString());
}
