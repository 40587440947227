export class StringDictionary {
    constructor(getPageNo) {
        this.getPageNo = getPageNo;
        this.idx = 1;
        /** backwards dictionary of
         * [repeated str:key]
         * */
        this.backDict = {};
        this.getKey = (str) => {
            let isNew = false;
            if (!this.backDict[str]) {
                isNew = true;
                this.backDict[str] = `${this.getPageNo() ?? 0}_${this.idx}`;
                this.idx += 1;
            }
            return [this.backDict[str], isNew];
        };
    }
}
export default class AttributeSender {
    constructor(options) {
        this.sendSetAttribute = (id, name, value) => {
            if (this.isDictDisabled) {
                const msg = [12 /* Type.SetNodeAttribute */, id, name, value];
                return this.app.send(msg);
            }
            else {
                const message = [
                    52 /* Type.SetNodeAttributeDict */,
                    id,
                    this.applyDict(name),
                    this.applyDict(value),
                ];
                return this.app.send(message);
            }
        };
        this.app = options.app;
        this.isDictDisabled = options.isDictDisabled;
        this.dict = new StringDictionary(this.app.session.getPageNumber);
    }
    applyDict(str) {
        const [key, isNew] = this.dict.getKey(str);
        if (isNew) {
            this.app.send([43 /* Type.StringDict */, key, str]);
        }
        return key;
    }
    clear() {
        this.dict = new StringDictionary(this.app.session.getPageNumber);
    }
}
